








































































































































import KCard from '@/@core/components/other/KCard.vue';
import KWrapper from '@/@core/components/other/KWrapper.vue';
import KPage from '@/@core/components/page/KPage.vue';
import KPageBody from '@/@core/components/page/KPageBody.vue';
import KPageHeader from '@/@core/components/page/KPageHeader.vue';
import KText from '@/@core/components/typography/KText.vue';
import HelloWorld from '@/components/HelloWorld.vue';
import {
  defineComponent,
  onMounted,
  ref,
  computed,
  watch
} from '@vue/composition-api';
import Icon from '../../ui/Icon.vue';
import Navigations from '../../ui/Navigations.vue';
import { useCategoryStore } from '@/modules/category/services/store';
import ProductItem from '@/modules/product/ui/ProductItem.vue';
import { useProductStore } from '@/modules/product/services/store';
import KDialog from '@/@core/components/dialogs/KDialog.vue';
import { isMobile } from '@/@core/helpers/useBreakpoint';
import ProductDetail from '@/modules/product/ui/ProductDetail.vue';
import KTableSearch from '@/@core/components/table/KTableSearch.vue';
import useTableOptions from '@/@core/helpers/table-options';
import KCurrencyInput from '@/@core/components/input/k-currency-input.vue';
import KComboboxInput from '@/@core/components/input/KComboboxInput.vue';
import KDebounceInput from '@/@core/components/input/k-debounce-input.vue';
import KCurrencyDebounceInput from '@/@core/components/input/k-currency-debounce-input.vue';

export default defineComponent({
  components: {
    KPage,
    KPageHeader,
    KText,
    KPageBody,
    KWrapper,
    HelloWorld,
    KCard,
    Icon,
    Navigations,
    ProductItem,
    KDialog,
    ProductDetail,
    KTableSearch,
    KCurrencyInput,
    KComboboxInput,
    KDebounceInput,
    KCurrencyDebounceInput
  },
  name: 'LandingProductPage',
  setup() {
    const categoryStore = useCategoryStore();
    const productStore = useProductStore();

    const showDetailDialog = ref(false);
    const selectedProduct = ref(null);

    const init = async () => {
      await categoryStore.getManyCategory();
      // await getManyProduct();
    };

    const selectProduct = (product: any) => {
      selectedProduct.value = { ...product };
      showDetailDialog.value = true;
    };

    const sortBy = ref('');
    const sortByList = [
      {
        text: "Terbaru",
        value: "Terbaru"
      },
      {
        text: "Harga (Dari Terendah)",
        value: "Harga-Terendah"
      },
      {
        text: "Harga (Dari Tertinggi)",
        value: "Harga-Tertinggi"
      },
    ]

    const isLoading = ref(false);
    const search = ref("");
    const showFilter = ref<null | boolean>(null);
    const filter = ref({
      sortBy: "Terbaru",
      priceStart: 0,
      priceEnd: 0,
    })
    const tableOptions = ref(useTableOptions({itemsPerPage: 12} as any));
    const totalPagination = computed(() => {
      const totalData = productStore.total
      const itemPerPage = tableOptions.value.itemsPerPage

      return Math.ceil((totalData) / itemPerPage)
    })

    const getManyProduct = async () => {
      isLoading.value = true;

      await productStore.getManyProduct({
        filter,
        options: tableOptions,
        search
      });

      setTimeout(() => {
        isLoading.value = false;
      }, 100)
    };

    onMounted(async () => {
      await init();
    });

    watch(filter, async (v) => {
      await getManyProduct()
      tableOptions.value.page = 1
    }, {
      immediate: false,
      deep: true
    });

    watch(tableOptions, async (v) => {
      await getManyProduct()
      // scrollToTop()
    }, {
      immediate: false,
      deep: true
    });
    
    watch(search, async (v) => {
      const isFirstPage = tableOptions.value.page == 1
      if (isFirstPage) return await getManyProduct()

      tableOptions.value.page = 1
    });

    return {
      categoryStore,
      sortBy,
      productStore,
      showDetailDialog,
      selectProduct,
      selectedProduct,
      isMobile,
      search,
      totalPagination,
      tableOptions,
      showFilter,
      filter,
      sortByList,
      isLoading
    };
  }
});
