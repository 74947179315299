import useErrorMessageHandler from '@/@core/helpers/errorMessageHandler';
import { currencyToNumber } from '@/@core/helpers/formatter';
import { toFormData } from '@/@core/helpers/formData';
import useQueryBuilder from '@/@core/helpers/query-builder';
import { useCallApi } from '@/@core/services/axiosCall';
import { GetManyQueries } from '@/modules/industry/services/store';
import {
  reactive,
  Ref,
  ref,
  toRefs,
  unref
} from '@vue/composition-api';
import { defineStore } from 'pinia';
import { marketingAreaList } from '../helpers/list';
import { useProductFilterToQuery } from '../helpers/product-filter-to-query';

const getMarketingArea = (data: any) => {
  const areas = marketingAreaList;
  const area = areas.find((a) => (a.value = data.marketingArea));

  return area;
};

const useProductDto = async (data: any) => {
  data['marketingArea'] = getMarketingArea(data)?.value;
  data.pricePerUnit = currencyToNumber(data.pricePerUnit);
  data.productionValue = currencyToNumber(data.productionValue);

  const formData = toFormData(data);
  if (data?.photos?.length) {
    data.photos.map((p: any) => formData.append('photos', p));
  }

  return formData;
};

export const useProductStore = defineStore('product', () => {
  const state = reactive({
    total: 0,
    products: [],
    product: null
  });

  const productRelations = [
    // 'categories',
    'photos',
    'industry'
  ];

  async function getManyProduct({
    filter = {},
    options,
    search
  }: GetManyQueries) {
    let query = useQueryBuilder({options, search, relations: productRelations})
    const filterQuery = useProductFilterToQuery(filter)
    query += filterQuery

    const { errorMessage, result, error } = await useCallApi({
      method: 'get',
      url: `/products${query}`
    });

    if (errorMessage) return useErrorMessageHandler(error);

    state.products = result.products;
    state.total = result.count;

    return result;


    // const params = `?relations=${productRelations.join(',')}`;
    // const { errorMessage, result, error } = await useCallApi({
    //   method: 'get',
    //   url: `/products${params}`
    // });

    // if (errorMessage) {
    //   useErrorMessageHandler(error);

    //   return null;
    // }

    // state.products = result.products;
    // state.total = result.count;

    // return result;
  }

  async function getOneProduct(id: string) {
    const params = `?relations=${productRelations.join(',')}`;
    const { errorMessage, result, error } = await useCallApi({
      method: 'get',
      url: `/products/${id + params}`
    });

    if (errorMessage) {
      useErrorMessageHandler(error);

      return null;
    }

    state.product = result.product;

    return result;
  }

  async function postProduct(data: any) {
    data = await useProductDto(data);

    const { errorMessage, result, error } = await useCallApi({
      method: 'post',
      url: `/products`,
      data: unref(data)
    });

    if (errorMessage) {
      useErrorMessageHandler(error);

      return null;
    }

    return result;
  }

  async function putProduct(data: any) {
    data = await useProductDto(data);

    const { errorMessage, result, error } = await useCallApi({
      method: 'put',
      url: `/products/${data.id}`,
      data: unref(data)
    });

    if (errorMessage) {
      useErrorMessageHandler(error);

      return null;
    }

    return result;
  }

  async function deleteProduct(data: any) {
    const { errorMessage, result, error } = await useCallApi({
      method: 'delete',
      url: `/products/${data.id}`
    });

    if (errorMessage) {
      useErrorMessageHandler(error);

      return null;
    }

    return result;
  }

  return {
    ...toRefs(state),
    getManyProduct,
    postProduct,
    deleteProduct,
    putProduct,
    getOneProduct
  };
});
