














































































import KCard from '@/@core/components/other/KCard.vue';
import { Product } from '@/modules/industry/types';
import { defineComponent, PropType } from '@vue/composition-api';
import KText from '@/@core/components/typography/KText.vue';
import { useNumberToCurrency } from '@/@core/helpers/formatter';
import ProductPhoto from './ProductPhoto.vue';
import { isMobile } from '@/@core/helpers/useBreakpoint';
import { useProductThumbnail } from '../helpers/productThumbnail';
export default defineComponent({
  components: { KCard, KText, ProductPhoto },
  name: 'ProductItem',
  props: {
    product: {
      type: Object as PropType<Product>,
      required: true
    }
  },
  setup() {
    const hasIndustry = (product: any) => !!product?.industry;
    const industryName = (product: any) =>
      product?.industry?.name || '-';

    return {
      useNumberToCurrency,
      hasIndustry,
      industryName,
      isMobile,
      useProductThumbnail
    };
  }
});
