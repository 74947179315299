












import { loadProductFile } from '@/@core/helpers/loader';
import {
  computed,
  defineComponent,
  PropType
} from '@vue/composition-api';

export default defineComponent({
  name: 'ProductPhoto',
  props: {
    photo: {
      type: [Object, File] as PropType<any | File>
    },
    small: {
      type: Boolean,
      default: false
    },
    size: {
      type: String || Number,
      default: '188px'
    }
  },
  setup(props) {
    const isObjectPhoto = computed(() => typeof props.photo == 'object')
    const loadPhoto = (photo: any) => {
      const hasPhotoPath = photo?.path;

      if (!hasPhotoPath) return loadProductFile('');

      return loadProductFile(photo.path);
    };

    const photoSize = computed(() =>
      // @ts-ignore
      props?.small ? '96px' : props.size
    );

    return {
      photoSize,
      loadPhoto,
      isObjectPhoto
    };
  }
});
