import { currencyToNumber } from "@/@core/helpers/formatter"
import { makeQueries } from "@/@core/helpers/make-queries"
import { unref } from "@vue/composition-api"

export const useSortByFilterToQuery = (filter: any) => {
  const _filter = unref(filter)
  const sortBy = _filter?.sortBy
    let sortByParam = ''

  if (sortBy) {
    const priceHighest = sortBy === 'Harga-Tertinggi'
    const priceLowest = sortBy === 'Harga-Terendah'
    const newestFirst = sortBy === 'Terbaru'

    if (priceHighest) sortByParam = "sort=-pricePerUnit"
    if (priceLowest) sortByParam = "sort=pricePerUnit"
    if (newestFirst) sortByParam = "sort=-createdAt"
    }

  return sortByParam
}

export const usePriceStartFilterToQuery = (filter: any) => {
  const _filter = unref(filter)
  const priceStart = _filter?.priceStart
    let priceStartParam = ''

  if (priceStart && priceStart != '0') priceStartParam = `priceStart=${currencyToNumber(priceStart)}`

  return priceStartParam
}

export const usePriceEndFilterToQuery = (filter: any) => {
  const _filter = unref(filter)
  const priceEnd = _filter?.priceEnd
    let priceEndParam = ''

  if (priceEnd && priceEnd != '0') priceEndParam = `priceEnd=${currencyToNumber(priceEnd)}`

  return priceEndParam
}

export const useProductFilterToQuery = (filter?: any, prefix: string = '&') => {
  let queries = '';
  const _filter = unref(filter)

  if (!_filter) return queries;

    const sortByParam = useSortByFilterToQuery(filter)
    const priceStartParam = usePriceStartFilterToQuery(filter)
    const priceEndParam = usePriceEndFilterToQuery(filter)

    if (sortByParam) queries = makeQueries(queries, sortByParam)
    if (priceStartParam) queries = makeQueries(queries, priceStartParam)
    if (priceEndParam) queries = makeQueries(queries, priceEndParam)
    

  return queries ? prefix + queries : '';
};
