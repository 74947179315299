













import {
  computed,
  defineComponent,
  onMounted,
  ref,
  Ref,
  watch
} from '@vue/composition-api';
import KBaseInput from './KBaseInput.vue';
import useInputStyles from '@/@core/styles/inputStyles';
import useInputRules from '@/@core/helpers/inputRules';
import { toCurrency } from '@/@core/helpers/formatter';
import { useDebounce } from '@vueuse/core';

export default defineComponent({
  components: { KBaseInput },
  name: 'KCurrencyDeboundInput',
  setup(props, { emit, attrs }) {
    const { label, rules, ...filteredAttrs } = attrs;

    const formattedRules: Ref<any[]> = computed(() =>
      rules ? useInputRules(rules as any[], attrs.value) : []
    );
    
    const tempValue = ref<any>('')
    const debouncedValue = useDebounce(tempValue, 500)
    const reRenderCount = ref(0)
    
    watch(() => debouncedValue.value, v => updateModelValue(v))

    const updateModelValue = (v: any) => emit('input', toCurrency(v as string))

    onMounted(() => {
      updateModelValue(attrs.value)
      tempValue.value = attrs.value
    });

    return {
      useInputStyles,
      filteredAttrs,
      formattedRules,
      toCurrency,
      tempValue,
      reRenderCount
    };
  }
});
