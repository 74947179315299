



























































































































import {
  computed,
  defineComponent,
  PropType,
  ref
} from '@vue/composition-api';
import { useNumberToCurrency } from '@/@core/helpers/formatter';
import KText from '@/@core/components/typography/KText.vue';
import { useLoadProductPhoto } from '../helpers/loadProductPhoto';
import ProductPhoto from './ProductPhoto.vue';
import useProductThumbnail from '../helpers/productThumbnail';
import { toWhatsAppNumber } from '@/modules/landing/helpers/to-whatsapp-number';
import { generateWhatsAppMessage } from '@/modules/landing/helpers/generate-whatsapp-message';
import { Product } from '@/modules/industry/types';

export default defineComponent({
  components: { KText, ProductPhoto },
  name: 'ProductDetail',
  props: {
    product: {
      type: Object as PropType<Product>,
      default: () => {}
    },
    industry: {
      type: Object,
      default: () => {}
    }
  },
  setup(props) {
    const selectedIndex = ref(0);
    const hasContacts = computed(() => props.industry.contacts || {});

    return {
      useLoadProductPhoto,
      useNumberToCurrency,
      useProductThumbnail,
      selectedIndex,
      toWhatsAppNumber,
      hasContacts,
      generateWhatsAppMessage
    };
  }
});
