import useErrorMessageHandler from "@/@core/helpers/errorMessageHandler"
import { useCallApi } from "@/@core/services/axiosCall"
import { reactive, Ref, ref, toRefs, unref } from "@vue/composition-api"
import { defineStore } from "pinia"

const useCategoryDto = async (data: any) => {
  return data
}

export const useCategoryStore = defineStore('category', () => {
    const state = reactive({
        total: 0,
        categories: [],
        category: null
    })

    const categoryRelations = [
        'parent',
        'childs',
    ]

    async function getManyCategory() {
        const params = `?relations=${categoryRelations.join(',')}`
        const {
            errorMessage,
            result,
            error
        } = await useCallApi({
            method: 'get',
            url: `/categories${params}`,
        })

        if (errorMessage) {
            useErrorMessageHandler(error)

            return null
        }

        state.categories = result.categories
        state.total = result.count

        return result
    }

    async function getOneCategory(id: string) {
        const params = `?relations=${categoryRelations.join(',')}`
        const {
            errorMessage,
            result,
            error
        } = await useCallApi({
            method: 'get',
            url: `/categories/${id + params}`,
        })

        if (errorMessage) {
            useErrorMessageHandler(error)

            return null
        }

        state.category = result.category

        return result
    }

    async function postCategory(data: any) {
        data = await useCategoryDto(data)

        const {
            errorMessage,
            result,
            error
        } = await useCallApi({
            method: 'post',
            url: `/categories`,
            data: unref(data)
        })

        if (errorMessage) {
            useErrorMessageHandler(error)

            return null
        }

        return result
    }

    async function putCategory(data: any) {
        data = await useCategoryDto(data)
        
        const {
            errorMessage,
            result,
            error
        } = await useCallApi({
            method: 'put',
            url: `/categories/${data.id}`,
            data: unref(data)
        })

        if (errorMessage) {
            useErrorMessageHandler(error)

            return null
        }

        return result
    }

    async function deleteCategory(data: any) {
        const {
            errorMessage,
            result,
            error
        } = await useCallApi({
            method: 'delete',
            url: `/categories/${data.id}`,
        })

        if (errorMessage) {
            useErrorMessageHandler(error)

            return null
        }

        return result
    }
    
    return {
        ...toRefs(state),
        getManyCategory,
        postCategory,
        deleteCategory,
        putCategory,
        getOneCategory,
    }
})
